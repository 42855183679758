// @import "@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
// html, body { height: 100%; }
@import 'variables';
@import 'common';

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  background-color: $background-light-grey;
  box-sizing: border-box;

  //style config for material datepicker
  --mat-datepicker-calendar-date-selected-state-background-color: #00a758;
  --mat-datepicker-calendar-date-selected-state-text-color: white;

  --mat-datepicker-calendar-header-text-weight: bold;
  --mat-datepicker-calendar-header-text-size: 1rem;
  --mat-datepicker-calendar-header-text-color: black;

  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 0.9rem;

  --mat-datepicker-calendar-date-disabled-state-text-color: gray;

  --mat-datepicker-calendar-date-text-color: #282b3e;
  --mat-datepicker-calendar-date-outline-color: transparent;
}

.mdc-button {
  border-radius: 2px !important;

  &__label {
    z-index: 0 !important;
  }
}

.mdc-button.mat-accent {
  background-color: $background-white !important;
  border: 2px solid $border-color-accent !important;
}


.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: white !important;
  color: #282b3e !important;
}

.mat-calendar-body-cell-content {
  border: none
}

.mat-calendar-body-selected {
  background-color: #00a758;
  color: white;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #282b3e;
  border-color: transparent;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(40, 43, 62, 0.54);
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: grey;
  cursor: not-allowed;
}

// change for the fix of z-index issue caused on progress bar because of sidenav library
.app-layout__left-menu .mat-drawer-container {
  z-index: 999 !important;
}

.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  cursor: not-allowed;
}

.mat-mdc-tooltip,
.mdc-tooltip__surface,
.mdc-tooltip__surface-animation {
  background-color: #282B3E;
  color: white;
  max-width: 355px;
}

.mdc-radio__inner-circle {
  color: green;
  top: 2px !important;
  left: 2px !important;
  width: 80% !important;
  height: 80% !important;
  border-width: 8px !important;
  /*inner circle color change*/
}

.mdc-radio__outer-circle {
  border-color: grey !important;
}

.errorCross {
  margin-left: 30px !important;
  margin-bottom: 50px !important;
  border: 2px solid lightgray !important;
  cursor: pointer !important;
}

.cdk-overlay-container {
  z-index: 9999; // changes for GFE-1632
}

//adding a custom media query to fit placeholder in the input box on password screen on small screen devices
app-password-screen {
  .password-field__input {
    @media screen and (max-width:370px) {
      input {
        font-size: 0.7rem !important;
      }
    }
  }
}