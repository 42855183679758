@import './setting';

body {
  .u-block {
    @media #{$media-pc} {
      margin: 0 auto; 
      max-width: 1086px;
      width: calc(100% - 40px);
    }
    @media #{$media-sp} { 
      padding-right: 5.4vw;
      padding-left: 5.4vw;
    }
    >:first-child {margin-top: 0;}
    >:last-child {margin-bottom: 0;}
  }
  .u-block--520 {
    margin: 0 auto;
    max-width: 520px;
    @media #{$media-sp} {
      max-width: 100%;
    }
  }
  .u-block--fit {
    margin: 0 auto;
    width: fit-content;
    max-width: 520px;
    @media #{$media-sp} {
      max-width: 100%;
    }
  }
  .u-block--720 {
    margin: 0 auto;
    max-width: 720px;
    @media #{$media-sp} {
      max-width: 100%;
    }
  }

  .u-show-pc {
    @media #{$media-sp} {
      display: none !important;
    }
  }
  
  .u-show-sp {
    @media #{$media-pc} {
      display: none !important;
    }
  }

  .u-show-sp-tab {
    display: none;
    @media #{$media-sp} {
      display: block !important;
    }
    @media #{$media-tab} {
      display: block !important;
    }
  }

  .u-show-tab {
    display: none;
    @media #{$media-tab} {
      display: block !important;
    }
  }
  
  .u-text-left {
    text-align: left !important;
  }
  .u-text-center {
    text-align: center !important;
  }
  .u-text-right {
    text-align: right !important;
  }
  .u-text-small {
    font-size: 1.4rem;
  }
  .u-font-bold {
    font-weight: bold !important;
  }
  .u-font-normal {
    font-weight: 400 !important;
  }
  .u-pt-0 {
    padding-top: 0 !important;
  }
  .u-pb-0 {
    padding-bottom: 0 !important;
  }
  .u-mt-0 {
    margin-top: 0 !important;
  }
  .u-mt-1e {
    margin-top: 1em !important;
  }
  .u-mt-2e {
    margin-top: 2em !important;
  }
  .u-mt-10 {
    margin-top: 10px !important;
  }
  .u-mt-20 {
    margin-top: 20px !important;
  }
  .u-mt-30 {
    margin-top: 30px !important;
  }
  .u-mt-40 {
    margin-top: 40px !important;
  }
  .u-mt-50 {
    margin-top: 50px !important;
  }
  .u-mt-60 {
    margin-top: 60px !important;
  }
  .u-mt-70 {
    margin-top: 70px !important;
  }
  .u-mt-80 {
    margin-top: 80px !important;
  }
  .u-mt-90 {
    margin-top: 90px !important;
  }
  .u-mt-100 {
    margin-top: 100px !important;
  }
  .u-mb-0 {
    margin-bottom: 0 !important;
  }
  .u-mb-10 {
    margin-bottom: 10px !important;
  }
  .u-mb-20 {
    margin-bottom: 20px !important;
  }
  .u-mb-30 {
    margin-bottom: 30px !important;
  }
  .u-mb-40 {
    margin-bottom: 40px !important;
  }
  .u-mb-50 {
    margin-bottom: 50px !important;
  }
  .u-mb-60 {
    margin-bottom: 60px !important;
  }
  .u-mb-70 {
    margin-bottom: 70px !important;
  }
  .u-mb-80 {
    margin-bottom: 80px !important;
  }
  .u-mb-90 {
    margin-bottom: 90px !important;
  }
  .u-mb-100 {
    margin-bottom: 100px !important;
  }

  .u-whitespace {
    white-space: nowrap;
  }

  .u-color-red {
    color: #EC6453;
  }

  @media #{$media-pc} {
    .u-hover {
      cursor: pointer;
      transition: ease opacity 0.5s;
      &:hover {
        opacity: 0.7;
      }
    }
    .u-text-left--pc {
      text-align: left !important;
    }
    .u-text-center--pc {
      text-align: center !important;
    }
    .u-text-right--pc {
      text-align: right !important;
    }
    .u-pt-0--pc {
      padding-top: 0 !important;
    }
    .u-pt-10--pc {
      padding-top: 10px !important;
    }
    .u-pt-20--pc {
      padding-top: 20px !important;
    }
    .u-pt-30--pc {
      padding-top: 30px !important;
    }
    .u-pt-40--pc {
      padding-top: 40px !important;
    }
    .u-pt-50--pc {
      padding-top: 50px !important;
    }
    .u-pt-60--pc {
      padding-top: 60px !important;
    }
    .u-pt-70--pc {
      padding-top: 70px !important;
    }
    .u-pt-80--pc {
      padding-top: 80px !important;
    }
    .u-pt-90--pc {
      padding-top: 90px !important;
    }
    .u-pt-100--pc {
      padding-top: 100px !important;
    }
    .u-pb-0--pc {
      padding-bottom: 0 !important;
    }
    .u-pb-10--pc {
      padding-bottom: 10px !important;
    }
    .u-pb-20--pc {
      padding-bottom: 20px !important;
    }
    .u-pb-30--pc {
      padding-bottom: 30px !important;
    }
    .u-pb-40--pc {
      padding-bottom: 40px !important;
    }
    .u-pb-50--pc {
      padding-bottom: 50px !important;
    }
    .u-pb-60--pc {
      padding-bottom: 60px !important;
    }
    .u-pb-70--pc {
      padding-bottom: 70px !important;
    }
    .u-pb-80--pc {
      padding-bottom: 80px !important;
    }
    .u-pb-90--pc {
      padding-bottom: 90px !important;
    }
    .u-pb-100--pc {
      padding-bottom: 100px !important;
    }
    .u-mt-0--pc {
      margin-top: 0 !important;
    }
    .u-mt-10--pc {
      margin-top: 10px !important;
    }
    .u-mt-20--pc {
      margin-top: 20px !important;
    }
    .u-mt-30--pc {
      margin-top: 30px !important;
    }
    .u-mt-40--pc {
      margin-top: 40px !important;
    }
    .u-mt-50--pc {
      margin-top: 50px !important;
    }
    .u-mt-60--pc {
      margin-top: 60px !important;
    }
    .u-mt-70--pc {
      margin-top: 70px !important;
    }
    .u-mt-80--pc {
      margin-top: 80px !important;
    }
    .u-mt-90--pc {
      margin-top: 90px !important;
    }
    .u-mt-100--pc {
      margin-top: 100px !important;
    }
    .u-mb-0--pc {
      margin-bottom: 0 !important;
    }
    .u-text-left--pc {
      text-align: left !important;
    }
    .u-text-small--pc {
      font-size: 1.4rem;
    }
    .u-text-center--pc {
      text-align: center !important;
    }
    .u-text-right--pc {
      text-align: right !important;
    }
    .u-w-10p--pc { width: 10% !important; }
    .u-w-15p--pc { width: 15% !important; }
    .u-w-20p--pc { width: 20% !important; }
    .u-w-25p--pc { width: 25% !important; }
    .u-w-30p--pc { width: 30% !important; }
    .u-w-35p--pc { width: 35% !important; }
    .u-w-40p--pc { width: 40% !important; }
    .u-w-45p--pc { width: 45% !important; }
    .u-w-50p--pc { width: 50% !important; }
    .u-w-55p--pc { width: 55% !important; }
    .u-w-60p--pc { width: 60% !important; }
    .u-w-65p--pc { width: 65% !important; }
    .u-w-70p--pc { width: 70% !important; }
    .u-w-75p--pc { width: 75% !important; }
    .u-w-80p--pc { width: 80% !important; }
    .u-w-85p--pc { width: 85% !important; }
    .u-w-90p--pc { width: 90% !important; }
    .u-w-95p--pc { width: 95% !important; }
    .u-w-100p--pc { width: 100% !important; }
    .u-fz-12--pc { font-size: 1.2rem !important; }
    .u-fz-13--pc { font-size: 1.3rem !important; }
    .u-fz-14--pc { font-size: 1.4rem !important; }
    .u-fz-15--pc { font-size: 1.5rem !important; }
    .u-fz-16--pc { font-size: 1.6rem !important; }
    .u-fz-17--pc { font-size: 1.7rem !important; }
    .u-fz-18--pc { font-size: 1.8rem !important; }
    .u-fz-19--pc { font-size: 1.9rem !important; }
    .u-fz-20--pc { font-size: 2.0rem !important; }
    .u-fz-21--pc { font-size: 2.1rem !important; }
    .u-fz-22--pc { font-size: 2.2rem !important; }
    .u-fz-23--pc { font-size: 2.3rem !important; }
    .u-fz-24--pc { font-size: 2.4rem !important; }
    .u-fz-25--pc { font-size: 2.5rem !important; }
    .u-fz-26--pc { font-size: 2.6rem !important; }
    .u-fz-27--pc { font-size: 2.7rem !important; }
    .u-fz-28--pc { font-size: 2.8rem !important; }
  }

  @media #{$media-sp} {
    .u-text-left--sp {
      text-align: left !important;
    }
    .u-text-center--sp {
      text-align: center !important;
    }
    .u-text-right--sp {
      text-align: right !important;
    }
    .u-pt-0--sp {
      padding-top: 0 !important;
    }
    .u-pt-10--sp {
      padding-top: 10px !important;
    }
    .u-pt-20--sp {
      padding-top: 20px !important;
    }
    .u-pt-30--sp {
      padding-top: 30px !important;
    }
    .u-pt-40--sp {
      padding-top: 40px !important;
    }
    .u-pt-50--sp {
      padding-top: 50px !important;
    }
    .u-pt-60--sp {
      padding-top: 60px !important;
    }
    .u-pt-70--sp {
      padding-top: 70px !important;
    }
    .u-pt-80--sp {
      padding-top: 80px !important;
    }
    .u-pt-90--sp {
      padding-top: 90px !important;
    }
    .u-pt-100--sp {
      padding-top: 100px !important;
    }
    .u-pb-0--sp {
      padding-bottom: 0 !important;
    }
    .u-pb-10--sp {
      padding-bottom: 10px !important;
    }
    .u-pb-20--sp {
      padding-bottom: 20px !important;
    }
    .u-pb-30--sp {
      padding-bottom: 30px !important;
    }
    .u-pb-40--sp {
      padding-bottom: 40px !important;
    }
    .u-pb-50--sp {
      padding-bottom: 50px !important;
    }
    .u-pb-60--sp {
      padding-bottom: 60px !important;
    }
    .u-pb-70--sp {
      padding-bottom: 70px !important;
    }
    .u-pb-80--sp {
      padding-bottom: 80px !important;
    }
    .u-pb-90--sp {
      padding-bottom: 90px !important;
    }
    .u-pb-100--sp {
      padding-bottom: 100px !important;
    }
    .u-mt-0--sp {
      margin-top: 0 !important;
    }
    .u-mt-10--sp {
      margin-top: 10px !important;
    }
    .u-mt-20--sp {
      margin-top: 20px !important;
    }
    .u-mt-30--sp {
      margin-top: 30px !important;
    }
    .u-mt-40--sp {
      margin-top: 40px !important;
    }
    .u-mt-50--sp {
      margin-top: 50px !important;
    }
    .u-mt-60--sp {
      margin-top: 60px !important;
    }
    .u-mt-70--sp {
      margin-top: 70px !important;
    }
    .u-mt-80--sp {
      margin-top: 80px !important;
    }
    .u-mt-90--sp {
      margin-top: 90px !important;
    }
    .u-mt-100--sp {
      margin-top: 100px !important;
    }
    .u-mb-0--sp {
      margin-bottom: 0 !important;
    }
    .u-text-left--sp {
      text-align: left !important;
    }
    .u-text-center--sp {
      text-align: center !important;
    }
    .u-text-right--sp {
      text-align: right !important;
    }
    .u-text-small--sp {
      font-size: 1.4rem;
    }
    .u-w-10p--sp { width: 10% !important; }
    .u-w-15p--sp { width: 15% !important; }
    .u-w-20p--sp { width: 20% !important; }
    .u-w-25p--sp { width: 25% !important; }
    .u-w-30p--sp { width: 30% !important; }
    .u-w-35p--sp { width: 35% !important; }
    .u-w-40p--sp { width: 40% !important; }
    .u-w-45p--sp { width: 45% !important; }
    .u-w-50p--sp { width: 50% !important; }
    .u-w-55p--sp { width: 55% !important; }
    .u-w-60p--sp { width: 60% !important; }
    .u-w-65p--sp { width: 65% !important; }
    .u-w-70p--sp { width: 70% !important; }
    .u-w-75p--sp { width: 75% !important; }
    .u-w-80p--sp { width: 80% !important; }
    .u-w-85p--sp { width: 85% !important; }
    .u-w-90p--sp { width: 90% !important; }
    .u-w-95p--sp { width: 95% !important; }
    .u-w-100p--sp { width: 100% !important; }
    .u-fz-12--sp { font-size: 1.2rem !important; }
    .u-fz-13--sp { font-size: 1.3rem !important; }
    .u-fz-14--sp { font-size: 1.4rem !important; }
    .u-fz-15--sp { font-size: 1.5rem !important; }
    .u-fz-16--sp { font-size: 1.6rem !important; }
    .u-fz-17--sp { font-size: 1.7rem !important; }
    .u-fz-18--sp { font-size: 1.8rem !important; }
    .u-fz-19--sp { font-size: 1.9rem !important; }
    .u-fz-20--sp { font-size: 2.0rem !important; }
    .u-fz-21--sp { font-size: 2.1rem !important; }
    .u-fz-22--sp { font-size: 2.2rem !important; }
    .u-fz-23--sp { font-size: 2.3rem !important; }
    .u-fz-24--sp { font-size: 2.4rem !important; }
    .u-fz-25--sp { font-size: 2.5rem !important; }
    .u-fz-26--sp { font-size: 2.6rem !important; }
    .u-fz-27--sp { font-size: 2.7rem !important; }
    .u-fz-28--sp { font-size: 2.8rem !important; }

  }
}