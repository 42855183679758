@use '@angular/material' as mat;

@import "@angular/material/theming";

@function my-mat-light-theme-foreground($color) {
    @return (
        base: $color,
        divider: $black-12-opacity,
        dividers: $black-12-opacity,
        disabled: rgba($color, 0.54),
        disabled-button: rgba($color, 0.54),
        disabled-text: rgba($color, 0.54),
        hint-text: rgba($color, 0.54),
        secondary-text: rgba($color, 0.87),
        icon: rgba($color, 0.87),
        icons: rgba($color, 0.87),
        text: rgba($color, 1),
        slider-off: rgba($color, 0.38),
        slider-off-active: rgba($color, 0.54),
        slider-min: rgba($color, 0.54)
    );
}

// https://material.angular.io/guide/typography
$manulife-typography: mat-typography-config($font-family: "Noto Sans CJK JP, Manulife JH Sans",
    $input: mat-typography-level(inherit, 1.5, 400));

// Manulife color guidelines https://manulife.frontify.com/document/25#/visual-system/colour
$primary-palette-map: (
    50: #e0f4eb,
    100: #b3e5cd,
    200: #80d3ac,
    300: #4dc18a,
    400: #26b471,
    500: #00a758,
    600: #009f50,
    700: #009647,
    800: #008c3d,
    900: #007c2d,
    A100: #a9ffc1,
    A200: #76ff9c,
    A400: #43ff77,
    A700: #2aff64,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
);

$accent-palette-map: (
    50: #ffefed,
    100: #ffd6d2,
    200: #ffbbb4,
    300: #ffa096,
    400: #ff8b80,
    500: #ff7769,
    600: #ff6f61,
    700: #ff6456,
    800: #ff5a4c,
    900: #ff473b,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffe5e4,
    A700: #ffcdca,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000
    )
);

$dark-navy: #282b3e;

$cws-primary: mat-palette($primary-palette-map);
$cws-accent: mat-palette($accent-palette-map);
$cws-warn: mat-palette($mat-red);

$cws-theme-base: mat.define-light-theme((color: (primary:$cws-primary, accent:$cws-accent, warn:$cws-warn)));

$cws-foreground: my-mat-light-theme-foreground($dark-navy);

$cws-theme: map-merge(
    $cws-theme-base,
    (
        foreground: $cws-foreground
    )
);

$space-between-containers: 16px;

$table-header-background: rgb(235, 235, 235);

$link-color: #0000c1;

$background: map-get($cws-theme, background);
$component-background: map-get($background, card);

$color-white: #FFFFFF;
$color-black: #000000;

// button color codes
$btn-color-coral: #EC6453;
$btn-color-dark: #D03A39;
$btn-color-white: #FFFFFF;

$text-color-dark: #282B3E;
$text-color-light-1: #5E6073;
$text-color-light-2: #8E90A2;

// background color codes
$background-dark: #282B3E;
$background-light2: #dddee4;
$background-light3: #5E6073;
$background-light4: #8E90A2;
$background-white: #FFFFFF;
$background-light-grey: #FAFAFA;
$background-light1-navy: #34384B;
$backgroud-snow-white: #f7f7f7;
// devider color codes
$divider: #EDEDED;

$table-header: #666666;

// text-font-sizes
$font-xs : .75em;
$font-sm : .875em;
$font-md : 1em;
$font-lg : 1.25em;
$font-xl : 1.5em;

// border colors
$border-color-light : #DBDBDB;
$border-color-dark: #808080c7;
$border-color-accent: #f44336;
$text-decoration-color: #00A758;
$text-familyInfo-confirmation: rgb(40, 43, 62);

// rakuraku h1 color
$h1-background : #f7f7f7;